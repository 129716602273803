require('./bootstrap');
require( 'jszip' );
require( 'pdfmake' );
require( 'datatables.net-bs4' );
require( 'datatables.net-buttons-bs4' );
require( 'datatables.net-buttons/js/buttons.html5.js' );
require( 'datatables.net-buttons/js/buttons.print.js' );
require( 'datatables.net-fixedheader-bs4' );
require( 'datatables.net-responsive-bs4' );


// composer require jszip
// composer require pdfmake
// composer require datatables.net-bs4
// composer require datatables.net-buttons-bs4
// composer require datatables.net-responsive-bs4